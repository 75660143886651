import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'bootstrap';

// Others
import VueAxios from "vue-axios";
import axios from "axios";

import vSelect from "vue-select";
import CKEditor from '@ckeditor/ckeditor5-vue2';

import "./assets/style.scss";
import "vue-select/dist/vue-select.css";
import Multiselect from 'vue-multiselect'

// register globally
Vue.component('multiselect', Multiselect)
Vue.component("v-select", vSelect);

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(CKEditor);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#vue-app");

