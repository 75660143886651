import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        preparation: {
            title: '',
            description: '',
            content: '',
            year: [],
            course: '',
            cycle: '',
            thumb: null, //used to preview
            thumbnail: null, //file from user
            thumbnail_gallery: null, //selection from gallery
            attachment: null,
            attachments: [],
            is_tic: false,
            is_dys: false,
            isAuthor: false,
            arePicturesFree: false,
            external_resource: JSON.stringify([{url: ''},]),
            tags: []
        },
        years: [],
        courses: [],
        agreeEmail: false,
    },
    mutations: {
        setCourses: (state, courses) => {
            state.courses = courses
        },

        setYears: (state, years) => {
            state.years = years
        },

        setPreparationTitle: (state, title) => {
            state.preparation.title = title
        },
        setPreparationContent: (state, content) => {
            state.preparation.content = content
        },
        setPreparationTags: (state, tags) => {
            state.preparation.tags = tags
        },
        setPreparationCycle: (state, cycle) => {
            state.preparation.cycle = cycle
        },
        setPreparationYear: (state, year) => {
            state.preparation.year = year
        },
        setPreparationCourse: (state, course) => {
            state.preparation.course = course
        },
        setPreparationDys: (state, dys) => {
            state.preparation.is_dys = dys
        },
        setPreparationTIC: (state, TIC) => {
            state.preparation.is_tic = TIC
        },

        setPreparationExternalResource: (state, resource) => {
            state.preparation.external_resource = resource
        },
        setThumbnail: (state, thumbnail) => {
            state.preparation.thumbnail = thumbnail
            state.preparation.thumb = thumbnail
            state.preparation.thumbnail_gallery = null
        },
        setThumbnailGallery: (state, thumbData) => {
            state.preparation.thumbnail_gallery = thumbData.id
            state.preparation.thumbnail = thumbData.image
            state.preparation.thumb = thumbData.image

        },
        clearThumbnail: (state) => {
            state.preparation.thumbnail_gallery = null
            state.preparation.thumbnail = null
            state.preparation.thumb = null
        },

        addDocument: (state, file) => {
            if (!state.preparation.attachments) {
                state.preparation.attachments = [];
            }
            state.preparation.attachments.push(file)
        },

        removeDocument: (state, index) => {
            state.preparation.attachments.splice(index, 1)
        },
        setIsAuthor: (state, value) => {
            state.preparation.isAuthor = value
        },
        setArePicturesFree: (state, value) => {
            state.preparation.arePicturesFree = value
        },
        appendAttachment: (state, value) => {
            state.preparation.attachments.push(value)
        },
        removeAttachment: (state, name) => {
            let index = state.preparation.attachments.findIndex(elem => elem.name === name)
            state.preparation.attachments.splice(index, 1)
        },
        setPreparation: (state, preparationObject) => {
            state.preparation = preparationObject
        },
        setAgreeEmail: (state, value) => {
            state.agreeEmail = value
        }
    },
    actions: {
        getCourses({commit}) {
            return new Promise((resolve, reject) => {
                try {
                    axios.get('/api/course/').then((response) => {
                        var courses = response.data;
                        commit('setCourses', courses)

                        resolve(courses);
                    })
                } catch (error) {
                    reject(error);
                }
            });
        },

        getYears({commit}) {
            return new Promise((resolve, reject) => {
                try {
                    axios.get('/api/year/').then((response) => {
                        commit('setYears', response.data)

                        resolve(response.data);
                    })
                } catch (error) {
                    reject(error);
                }
            });
        },

        getMyPreparation({commit}, url) {
            return new Promise((resolve, reject) => {
                try {
                    axios.get(url).then((response) => {
                        resolve(response.data);
                    })
                } catch (error) {
                    reject(error);
                }
            });
        },

        fetchPreparationSingle({dispatch, commit}, url) {
            return dispatch('getMyPreparation', url).then(data => {
                commit('setPreparation', data)
                return data
            })
        },

        prepareFormData({state}) {
            return new Promise((resolve, reject) => {
                let preparation = state.preparation;
                let fieldsErrors = []

                let isValidHttpUrl = function (string) {
                    let url;
                    try {
                        url = new URL(string);
                    } catch (_) {
                        return false;
                    }
                    return url.protocol === "http:" || url.protocol === "https:"
                }

                if (
                    !preparation.title.length ||
                    !preparation.content.length ||
                    !preparation.year.length ||
                    preparation.course === '' ||
                    preparation.cycle === ''
                ) {
                    console.log("incomplete form")
                    fieldsErrors.push("Veuillez vérifier les champs obligatoires:")
                    if (!preparation.title.length) {
                        fieldsErrors.push("Titre manquant.")
                    }
                    if (!preparation.content.length) {
                        fieldsErrors.push("Description manquante.")
                    }
                    if (preparation.cycle === '') {
                        fieldsErrors.push("Cycle manquant.")
                    }
                    if (!preparation.year.length) {
                        fieldsErrors.push("Année manquante.")
                    }
                    if (preparation.course === '') {
                        fieldsErrors.push("Cours manquant.")
                    }

                    reject(fieldsErrors)
                    return
                }

                let formData = new FormData();
                formData.append('title', preparation.title)
                formData.append('content', preparation.content)
                formData.append('course', preparation.course.slug)
                formData.append('cycle', preparation.cycle.slug)
                formData.append('is_dys', preparation.is_dys)
                formData.append('is_tic', preparation.is_tic)

                // https://github.com/encode/django-rest-framework/issues/1303#issuecomment-36137350
                if (!preparation.thumbnail_gallery)
                    formData.append('thumbnail_gallery', '')
                else
                    formData.append('thumbnail_gallery', preparation.thumbnail_gallery)

                if (preparation.thumbnail instanceof File)
                    formData.append('thumbnail', preparation.thumbnail)
                else if (!preparation.thumbnail)
                    formData.append('thumbnail', '')

                if (preparation.external_resource && preparation.external_resource.length) {
                    let links = JSON.parse(preparation.external_resource)
                    //remove empty input
                    links = links.filter(link => link.url !== '')
                    let allGood = links.every(item => isValidHttpUrl(item.url) === true)
                    console.log(links, allGood);

                    if (!allGood) {
                        fieldsErrors.push("Ressource externe, lien invalide. Vérifiez que les liens commencent par http:// ou https://")
                        reject(fieldsErrors)
                        return
                    }
                    formData.append('external_resource', JSON.stringify(links))
                }

                formData.append('publication_datetime', new Date().toISOString())

                preparation.year.forEach(elem => formData.append('year', elem.slug))
                preparation.attachments.forEach(elem => formData.append('attachments', elem.id))

                let newTags = preparation.tags.filter(tag => !tag.hasOwnProperty('pk'))
                let existingTags = preparation.tags.filter(tag => tag.hasOwnProperty('pk'))
                console.log(newTags)
                console.log(existingTags)

                existingTags.forEach(elem => formData.append('tags', elem.pk))

                formData.append('agree_email', state.agreeEmail)
                resolve({formData: formData, newTags: newTags})
            })
        },

        createPrepa({}, formData) {
            // console.warn("createPrepa");
            return axios.post('/api/preparations/', formData)
        },

        updatePrepa({}, prepData) {
            // console.warn('updatePrepa')
            return axios.put(`/prep/api/edit/${prepData.id}/`, prepData.formData)
        },

        sendData({commit, state, dispatch}, prepId) {
            return new Promise((resolve, reject) => {

                dispatch('prepareFormData')
                    .then(data => {
                        console.log('formData', data)
                        let formData = data.formData
                        let newTags = data.newTags
                        if (newTags.length) {
                            axios.post('/prep/api/tags_update/', newTags)
                                .then((response) => {
                                    // console.warn('/api/tags/ then')
                                    response.data.forEach(elem => formData.append('tags', elem.pk))
                                    if (prepId > 0)
                                        dispatch('updatePrepa', {id: prepId, formData: formData}).then(response => resolve(response))
                                    else
                                        dispatch('createPrepa', formData).then(response => resolve(response))
                                }).catch((error) => {
                                return reject(["problème de mots clés"]);
                            })
                        } else {
                            // console.log("prepareFormData then else");
                            if (prepId > 0)
                                dispatch('updatePrepa', {
                                    id: prepId,
                                    formData: formData
                                }).then(response => resolve(response))
                            else
                                dispatch('createPrepa', formData).then(response => resolve(response))
                        }
                    })
                    .catch(error => reject(error))

            });
        },
    }
});
