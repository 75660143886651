<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: "App",
		components: {},
		data: function() {
			return {};
		},
		created() {},
		methods: {},
		mounted() {},
	};
</script>
